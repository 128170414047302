 /*
  * Lightbox gallery
  */

 $('.block-gallery').lightGallery({
     selector: '.post-gallery-item',
     share: true,
     download: true,
     rotate: false,
     showThumbByDefault: true,
     loop: false,
     slideEndAnimatoin: false,
     thumbnail: true,
     allowMediaOverlap: true,
     toggleThumb: true,
     youtubePlayerParams: {
         loadYoutubeThumbnail: true,
         youtubeThumbSize: 'default',
         modestbranding: 1,
         showinfo: 0,
         rel: 0
     }
 });

 /*
  * Owl carousel
  */

 $('.owl-block').owlCarousel({
     margin: 20,
     items: 4,
     loop: true,
     autoplay: true,
     autoplayTimeout: 4500,
     nav: false,
     dots: false,
     animateOut: 'fadeOut',
     // navText: ["<i class='dr-arrow-left'></i>", "<i class='dr-arrow-right'></i>"],
     // navContainer: '#customNav',
     responsive: {
         0: {
             items: 1
         },
         700: {
             items: 2,
             margin: 0,
         },
         1200: {
             items: 3,
         }
     }
 });

 /*
  * Blocks scroll smoth
  */
 $(document).on('click', '.table-of-contents-wrapper a[href^="#"]', function (event) {
     event.preventDefault();

     $('html, body').animate({
         scrollTop: $($.attr(this, 'href')).offset().top - 120
     }, 500);
 });


//  /*
//   * Table blocks add class
//   */
//  function isOnScreen(elem) {
//      // if the element doesn't exist, abort
//      if (elem.length == 0) {
//          return;
//      }
//      var $window = jQuery(window)
//      var viewport_top = $window.scrollTop()
//      var viewport_height = $window.height()
//      var viewport_bottom = viewport_top + viewport_height
//      var $elem = jQuery(elem)
//      var top = $elem.offset().top
//      var height = $elem.height()
//      var bottom = top + height

//      return (top >= viewport_top && top < viewport_bottom) ||
//          (bottom > viewport_top) && (top < viewport_bottom) ||
//          (height > viewport_height && top <= viewport_top && bottom >= viewport_bottom)
//  }


//  window.addEventListener('scroll', function (e) {
//      var el = $('.stuck');
//      var elnew = $('.pre-stuck');
//      var viewport_top = $(window).scrollTop();
//      var el_top = $('.pre-stuck').offset().top
//      var el_top_new = elnew.offset().top


//      var containerWidth = $('.container').outerWidth();
//      var sectionWidth = $('#single-post section').outerWidth();
//      var tableWraperWidth = (sectionWidth - containerWidth) / 2;
//      var windowWidth = $(window).width();
//      var rightSpace = (windowWidth - sectionWidth) / 2;

//      if (isOnScreen(elnew) || viewport_top < el_top || viewport_top < el_top_new) {
//          $('.pre-stuck').removeClass('active');
//          $('.table-of-contents-parent').removeClass('active');

//          $('.table-of-contents-parent').css({
//              'width': '100%',
//              'max-width': '100%',
//              'right': 'auto',
//          })
//      } else {
//          $('.table-of-contents-parent').addClass('active');
//          $('.table-of-contents-parent').removeClass('stuck');


//          $('.table-of-contents-parent').css({
//              'width': tableWraperWidth,
//              'max-width': tableWraperWidth,
//              'right': rightSpace,
//          })
//      }
//  });

//  // Click on title of table contents
//  $('.title-table .hide').on('click', function () {
//      $('.table-of-contents-wrapper').toggleClass('show');

//      if ($('.table-of-contents-wrapper').hasClass('show')) {
//          $(this).text('Hide')
//      } else {
//          $(this).text('Show');
//      }
 
//  });

//  // Click on icon show/hide
//  $('.table-of-contents-wrapper i').on('click', function () {
//      console.log($(this));
//      $(this).next('.sublinks').toggleClass('active');
//  });

//  // Table of content mobile fix
//  if (window.outerWidth < 425) {
//      $('.table-of-contents-wrapper').removeClass('show');
//      $('.title-table .hide').text('Show');

//      $('.table-of-contents-wrapper a').on('click', function () {
//          $('.table-of-contents-wrapper').removeClass('show');
//          $('.title-table .hide').text('Show');
//      });
//  }